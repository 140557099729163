
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .gap {
        height: 20px;
    }
    .v-size--x-large {
        padding: 5px !important;
    }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
    .gap {
        height: 30px;
    }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
    .gap {
        height: 0px;
    }
}

.occurence-options {
    .v-radio--is-disabled {
        opacity: 0.5;
    }
}
